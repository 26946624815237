<script>
export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="mb-1 fw-semibold">발신자명</div>
        <div class="form-control bg-light">
          <strong>{{ data.sender_name }}</strong>
          <img v-if="data.sender_social" :src="require(`@/assets/images/social/icon_${data.sender_social.toLowerCase()}.png`)" class="align-text-top" height="16" />
        </div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">발신자 휴대폰</div>
        <div class="form-control bg-light">{{ data.sender_phone }}&nbsp;</div>
      </div>
      <div class="mb-2">
        <div class="mb-1 fw-semibold">수신자명</div>
        <div class="form-control bg-light">
          <strong>{{ data.receiver_name }}</strong>
          <img v-if="data.receiver_social" :src="require(`@/assets/images/social/icon_${data.receiver_social.toLowerCase()}.png`)" class="align-text-top" height="16" />
        </div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">수신자 휴대폰</div>
        <div class="form-control bg-light">{{ data.receiver_phone }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">메시지</div>
        <div class="form-control bg-light">{{ data.message }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">상태</div>
        <div class="form-control bg-light"><span :class="data.status_class">{{ data.status_message }}</span>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">요청일시</div>
        <div class="form-control bg-light">{{ data.created_at ? data.created_at : '-' }}</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">승인일시</div>
        <div class="form-control bg-light">{{ data.accept_date ? data.accept_date : '-' }}</div>
      </div>
    </div>
  </b-modal>
</template>
